.page-container {
    background-color: #3f4d67;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 70px;
    overflow-y: auto;
}

.message-box {
    position: relative;
    background-color: white;
    max-width: 650px;
    width: 80%;
    border-radius: 1rem;
    /* box-shadow: 0 0 10px 0px black; */
    padding: 10px;
    text-align: center;
}

.logo-container {
    position: absolute;
    border-radius: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -70px;
    background-color: white;
    box-shadow: 0 0 10px 0px black;
}

.message-box h1 {
    text-align: center;
    font-weight: bold;
    font-size: 1.65rem;
    margin-top: 75px;
    margin-bottom: 20px;
}

.message-box p {
    padding: 0 20px;
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    overflow-wrap: break-word;
    -ms-hyphens: manual;
    -moz-hyphens: manual;
    -webkit-hyphens: manual;
    hyphens: manual;
}

.signout-button {
    border: none;
    outline: none;
    color: white;
    background-color: #3f4d67;
    cursor: pointer;
    font-size: 1.15rem;
    line-height: 1.15rem;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signout-button:focus {
    outline: none;
}

.signout-button:hover {
    color: hsl(0, 0%, 75%);
    text-decoration: underline;
}

.power-icon {
    line-height: 1.15rem;
    margin-right: 8px;
    vertical-align: middle;
}

.dashboard-button {
    background-color: hsl(211, 100%, 55%);
    border-radius: 0.5rem;
    outline: none;
    border: 1px solid hsl(211, 100%, 40%);
    padding: 12px 10px;
    font-size: 1.25rem;
    color: white;
    cursor: pointer;
    margin-bottom: 15px;
}

.dashboard-button:hover {
    background-color: hsl(211, 100%, 40%);
}

hr {
    margin-bottom: 8.5px;
    text-align: center;
    width: 60%;
}

.resend-span {
    background-color: white;
    border: none;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
}


.resend-button {
    color: hsl(211, 100%, 55%);
    cursor: pointer;
    font-size: 1.05rem;
    font-weight: bolder;
}

.resend-message {
    color: black;
    cursor: default;
    font-size: 0.9rem;
    font-weight: normal;
}

.resend-button:hover {
    color: hsl(211, 100%, 40%);
}

.resend-button:focus {
    outline: none;
    border: none;
}

.invsibile {
    visibility: hidden;
}

.reveal-animation {
    opacity: 0;
    animation: reveal 1.2s cubic-bezier(0.77, 0, 0.175, 1) 0.5s forwards;
}

.load {
    display: inline-block;
	width: 1rem;
	height: 1rem;
	margin: 0;
    margin-right: 3px;
	border: solid 2px grey;
	border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
	 -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name:             rotate; 
    -webkit-animation-duration:         1.0s; 
    -webkit-animation-iteration-count:  infinite;
    -webkit-animation-timing-function: linear;
    	
    transition: all 0.5s ease-in;
    animation-name: rotate; 
    animation-duration: 1.0s; 
    animation-iteration-count:  infinite;
    animation-timing-function: linear; 
}

@keyframes reveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
 

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}