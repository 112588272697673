.popover-overlay {
    position: absolute;
    margin-top: -70px;
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%; 
    height: calc(100% + 70px); 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4); 
    z-index: 2; 
}


.popover-box {
    margin-top: -70px;
    background-color: white;
    width: 80%;
    max-width: 700px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    max-height: 300px;
    border-radius: 0.4rem;
    gap: 10px;
}


.popover-box h3 {
    margin: 0;
    text-align: center;
    padding: 0 15px;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}

.popover-box button {
    cursor: pointer;
    margin-top: 10px;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #3f4d67;
    color: white;
    font-family: inherit;
    border: none;
    outline: none;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;
}