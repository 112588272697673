.pass-strength-checker-box {
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.3s ease-in;
}


.hide-pass-strength {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-out;
}


.pass-strength-title {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 5px;
    margin-bottom: 0;
}